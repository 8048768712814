/**
 * Tracker session.
 * 
 * @author Gilang Romadhanu Tartila
 */
"use strict";

// let iframeTimeout = null;

import { COOKIE_NAME_SESSION } from '../constants';
import { setCookieMultiple, getCookie, generateSessionId } from '../helpers';

// const DOMAINS = [
// 	'https://sociolla.com',
// 	'https://lilla.id',
// 	'https://soco.id',
// 	'https://uat-sociolla-web.sociolabs.io',
// ];

/**
 * Create new session
 */
export function createSession() {
	const newSession = generateSessionId();
	setCookieMultiple(COOKIE_NAME_SESSION, newSession, { expiry: 30, string: true });
	return newSession;
}

/**
 * Update session
 */
export function updateSession(session) {
	setCookieMultiple(COOKIE_NAME_SESSION, session, { expiry: 30, string: true });
	return session;
}

/**
 * Get existing session id
 * force: no update expired date & force to create new session when has active session
 */
export function fetchSession(force = false) {
	const exist = generateSessionFromRoute();
	if (exist) {
		return exist;
	}
	const session = getCookie(COOKIE_NAME_SESSION);
	if (!force && session) {
		updateSession(session);
		return session;
	}
	return createSession();
}

/**
 * Starting session
 * force: no update expired date & force to create new session when has active session
 */
export function startSession(force = false) {
	return fetchSession(force);
}

/**
 * Ending session
 */
export function endSession() {
	return createSession();
}

/**
 * Generate session id bang route query url
 */
export function generateSessionFromRoute() {
	try {
		const query = new URLSearchParams(window.location.search);
		if (query.get('session_id')) {
			const sessionId = query.get('session_id');
			updateSession(sessionId);
			return sessionId;
		}
		return null;
	} catch (error) {
		return null;
	}
}

/**
 * Initiazing session
 */
export function initiate() {
	// console.log('%cSociolla Tech', 'color: #ff0066; text-shadow: 1px 1px 2px black, 0 0 1em white, 0 0 0.2em white; font-size: 16px;');
	// console.log('%cInitializing session....', 'color: #00f7ff');

	// Initial session
	startSession();

	// Reset session after clicking something
	window.addEventListener("click", function () {
		startSession();
	});

	// Set initial session for each domains
	// window.addEventListener("load", function () {
	// 	const exist = generateSessionFromRoute();
	// 	if (exist) {
	// 		return;
	// 	}

	// 	const session_id = fetchSession();
	// 	iframeTimeout = setTimeout(() => {
	// 		for (let idx = 0; idx < DOMAINS.length; idx++) {
	// 			const domain = DOMAINS[idx];
	// 			const id = `iframe-${domain}`;
	// 			if (!document.getElementById(id) && domain.indexOf(window.location.hostname) == -1) {
	// 				const iframe = document.createElement("iframe");
	// 				iframe.style.display = "none";
	// 				iframe.id = id;
	// 				iframe.src = `${domain}?session_id=${session_id}`;
	// 				document.body.appendChild(iframe);
	// 				setTimeout(() => {
	// 					const iframeExist = document.getElementById(id);
	// 					if (iframeExist) {
	// 						iframeExist.contentWindow.postMessage({ session_id }, domain)
	// 						// console.log('send post message here', domain, session_id);
	// 					}
	// 				}, 500);
	// 			}
	// 		}
	// 	}, 3000);
	// });

	// Set session id in iframe
	// window.addEventListener('message', function(e) {
	// 	for (let idx = 0; idx < DOMAINS.length; idx++) {
	// 		const domain = DOMAINS[idx];
	// 		if (e && e.data && e.data.session_id && e.origin === domain) {
	// 			const exist = generateSessionFromRoute();
	// 			if (exist) {
	// 				return;
	// 			}
	// 			// console.log('received post message here', domain, e.data.session_id)
	// 			updateSession(e.data.session_id);
	// 		}
	// 	}
	// }, false);

	// Remove iframe on unloaded
	// window.addEventListener("unload", function () {
	// 	clearTimeout(iframeTimeout);
	// 	for (let idx = 0; idx < DOMAINS.length; idx++) {
	// 		const domain = DOMAINS[idx];
	// 		const el = document.getElementById(`iframe-${domain}`);
	// 		if (el) {
	// 			el.remove();
	// 		}
	// 	}
	// })
}