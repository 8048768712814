/**
 * UTM Tracker all javascript platform.
 * 
 * @author Gilang Romadhanu Tartila
 */
"use strict";

import { getCookie, setCookieMultiple } from '../helpers';
import { fetchSession } from './session';
import { COOKIE_NAME_UTM, COOKIE_NAME_AVAILABLE } from '../constants';

/**
 * Create expired time for utm data
 */
const createExpiredDate = (type) => {
  // utm type = [google,meta,moe,tiktok,rtb]
  const visit = new Date();

  let time = 60 * 24 * 7 * 60000; // 7 days as default (facebook,tiktok,rtb)
  if (type) {
    if (type == 'google') {
      time = 60 * 24 * 30 * 60000; // 30 days
    } else if (type == 'moe') {
      time = 60 * 36 * 60000; // 36 hours
    }
  }

  return new Date(visit.getTime() + time); // expired time
}

/**
 * Checking expired time for utm data
 */
const checkActiveUtm = (utm) => {
  if (!utm.expired_at) {
    return {};
  }
  const now = new Date();
  const expired = new Date(utm.expired_at);
  return now.getTime() < expired.getTime() ? utm : {};
};

/**
 * Remove utm query when has no active utm data
 */
const onCheckUtmQuery = (data) => {
  const has_route_query = location.search ? location.search.indexOf('utm_') > -1 : false;
  if (has_route_query && !(data && Object.keys(data).length)) {
    for (let idx = 0; idx < COOKIE_NAME_AVAILABLE.length; idx++) {
      const qr = COOKIE_NAME_AVAILABLE[idx];
      try {
        if (history && history.replaceState) {
          history.replaceState(
            null,
            '',
            `${location.pathname}${location.search
              .replace(new RegExp('[?&]' + qr + '=[^&]+', 'gi'), '')
              .replace(/^&/, '?')}${location.hash}`
          );
        }
      } catch (err) { }
    }
  }
}

/**
 * Get existing utm data
 */
export function getExistingUtm() {
  let data = getCookie(COOKIE_NAME_UTM);

  if (data) {
    try {
      data = checkActiveUtm(data);
    } catch (error) {
      data = {};
    }
  } else {
    data = {};
  }

  if (typeof data != 'object') {
    data = {};
  }

  setCookieMultiple(COOKIE_NAME_UTM, data);

  return data;
};

/**
 * Get utm data from link
 */
export function getUtmDataFromRoute() {
  // check existing utm lists
  const data = getExistingUtm();
  let newQuery = {};

  try {
    const search = location.search.substring(1);
    newQuery = JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`)
  } catch (err) { };

  if (newQuery.utm_source || newQuery.utm_medium || newQuery.utm_campaign) {
    try {
      const query = JSON.parse(JSON.stringify(newQuery));
      const queries = Object.keys(query);
      const obj = {
        visited_at: new Date(),
      };

      // get utm data from route
      for (let i = 0; i < queries.length; i++) {
        const qr = queries[i];

        // store utm data only
        if (COOKIE_NAME_AVAILABLE.includes(qr)) {
          obj[qr] = query[qr];
          if (qr == 'utm_campaign' && obj[qr]) {
            const split = obj[qr].split('_');
            obj['type'] = split[split.length - 1].toLowerCase();
            obj['expired_at'] = createExpiredDate(obj['type']);
          } else {
            obj['type'] = obj.utm_source || 'campaign';
            obj['expired_at'] = createExpiredDate(obj['type']);
          }
        }
      }

      // check new data has correct data
      if (obj.visited_at && (obj.utm_campaign || obj.utm_source || obj.utm_medium)) {
        if (
          (
            obj.utm_source &&
            obj.aff_sid &&
            obj.affiliate_code
          ) || (
            data.utm_campaign != obj.utm_campaign &&
            data.utm_source != obj.utm_source &&
            data.utm_medium != obj.utm_medium
          )
        ) {
          data.type = obj.type;
          data.utm_campaign = obj.utm_campaign;
          data.utm_source = obj.utm_source;
          data.utm_medium = obj.utm_medium;
          data.visited_at = obj.visited_at;
          data.expired_at = obj.expired_at;
          if (obj.traffic_id) {
            data.traffic_id = obj.traffic_id;
          }
          if (obj.aff_sid) {
            data.aff_sid = obj.aff_sid;
          }
          if (obj.affiliate_code) {
            data.affiliate_code = obj.affiliate_code;
          }
        }
      }
    } catch (err) { }
  }

  // store new utm data lists
  const options = {};
  if (data && data.expired_at) {
    const today = new Date();
    const expired = new Date(data.expired_at);
    const miliseconds = (expired - today);
    options.expiry = Math.round(((miliseconds % 86400000) % 3600000) / 60000); // Converted different time to minutes
  }
  setCookieMultiple(COOKIE_NAME_UTM, data, options);

  // Remove utm query when its not exists
  onCheckUtmQuery(data);
}

/**
 * Assign latest utm data to api request
 */
export function getUtmForParameter(params) {
  // check existing utm lists
  const data = getExistingUtm();

  // get latest utm
  if (data && Object.keys(data).length) {
    for (let idx = 0; idx < COOKIE_NAME_AVAILABLE.length; idx++) {
      const child = COOKIE_NAME_AVAILABLE[idx];
      // Set parameters for affiliation
      if (data[child]) {
        if (['traffic_id', 'aff_sid', 'affiliate_code'].includes(child)) {
          params['session_id'] = fetchSession();
          if (data.utm_source) {
            params['utm_source'] = data.utm_source;
          }
          if (data.traffic_id && child == 'traffic_id') {
            params[child] = data[child] || '';
          }
          if (!(params.affiliation && params.affiliation.code)) {
            params.affiliation = {
              code: data.affiliate_code,
              data: {}
            }
            if (data.utm_source) {
              params.affiliation.data['utm_source'] = data.utm_source;
            }
          }
          params.affiliation.data[child] = data[child] || '';
        } else {
          params[child] = data[child] || '';
        }
      }
    }
  }

  // Remove utm query when its not exists
  onCheckUtmQuery(data);
}
