/**
 * Helper for tracking.
 * 
 * @author Gilang Romadhanu Tartila
 */
"use strict";

/**
 * Get existing domain
 */
export function getDomain() {
	const fullDomain = window.location.hostname.split('.');
	let domain = fullDomain[0];
	if (fullDomain.length > 1) {
		domain = fullDomain[1] + '.' + fullDomain[2];
	}
	return domain;
}

/**
 * Generate Session ID
 */
export function generateSessionId() {
	try {
		if (performance && typeof performance !== 'undefined' && typeof performance.now === 'function') {
			d += performance.now(); //use high-precision timer if available
		}
		const formattedUUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			const r = (d + Math.random() * 16) % 16 | 0;
			d = Math.floor(d / 16);
			return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
		});
		return formattedUUID;
	} catch (error) {
		const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
		let uuid = '';
		for (let i = 0; i < 32; i++) {
			const randomNumber = Math.floor(Math.random() * characters.length);
			const randomCharacter = characters.charAt(randomNumber);
			uuid += randomCharacter;
		}
		const formattedUUID = `${uuid.substr(0, 8)}-${uuid.substr(8, 4)}-${uuid.substr(12, 4)}-${uuid.substr(16, 4)}-${uuid.substr(20)}`;
		return formattedUUID;
	}
}

/**
 * Save Cookie
 */
export function setCookie(cookieName, cookieValue, options = {}) {
	const date = new Date();
	date.setTime(date.getTime() + ((options.expiry || 0) * 60 * 1000));
	const expires = options.expiry ? `expires=${date.toUTCString()};` : '';
	const domain = options.domain ? `domain=${options.domain};` : '';
	const finalValue = options.string ? cookieValue : JSON.stringify(cookieValue);
	document.cookie = `${cookieName}=${finalValue}; ${expires} path=/; secure; samesite=None; ${domain}`;
}

/**
 * Save Cookie
 */
export function setCookieMultiple(cookieName, cookieValue, options = {}) {
	const DOMAINS = [
		'.sociolla.com',
		'localhost',
		'.sociolla.info',
		'.lilla.id',
		'.soco.id',
		'.sociolabs.io'
	];

	for (let idx = 0; idx < DOMAINS.length; idx++) {
		const domain = DOMAINS[idx];
		const finalOptions = options || {};
		setCookie(cookieName, cookieValue, { ...finalOptions, domain });
	}
}

/**
 * Get saved Cookie
 */
export function getCookie(cookieName) {
	const name = `${cookieName}=`;
	const decodedCookie = decodeURIComponent(document.cookie);
	const cookieArray = decodedCookie.split(';');

	for (let i = 0; i < cookieArray.length; i++) {
		let cookie = cookieArray[i];
		while (cookie.charAt(0) === ' ') {
			cookie = cookie.substring(1);
		}
		if (cookie.indexOf(name) === 0) {
			const result = cookie.substring(name.length, cookie.length);
			try {
				return JSON.parse(result);
			} catch (error) {
				return result;
			}
		}
	}

	return null;
}

/**
 * Remove Cookie
 */
export function removeCookie(cookieName) {
	document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}

/**
 * Get device information
 */
export function getDeviceInformation() {
	// User Agent
	const userAgent = navigator.userAgent;

	// Operating System
	const operatingSystem = navigator.platform;

	// Browser Name and Version:
	const browserName = navigator.appName;
	const browserVersion = navigator.appVersion;

	return {
		user_agent: userAgent,
		operating_system: operatingSystem,
		browser_name: browserName,
		browser_version: browserVersion
	}
}
